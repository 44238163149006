<template>
  <div>
    <div v-for="(dateRange, index) in dateRanges" :key="index">
      <span>{{ formatDate(dateRange.fromDate) }}</span> to <span>{{ formatDate(dateRange.toDate) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateRanges',

  props: {
    dateRanges: {
      type: Array,
      required: true
    }
  },

  methods: {
    formatDate (value) {
      if (value) {
        return Intl.DateTimeFormat(navigator.language, {
          year: 'numeric', month: 'numeric', day: 'numeric'
        }).format(new Date(value))
      } else {
        return ''
      }
    },
  }
}
</script>

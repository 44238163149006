<template>
  <div class="two-sided">
    <div>
      <Projects />
      <Investment />
    </div>
    <div>
      <VolunteerAvailability v-if="memberStore.member.volunteer" />
      <MemberContactDetails />
    </div>
  </div>
</template>

<script>
import Investment from './Investment.vue';
import MemberContactDetails from './MemberContactDetails.vue'
import Projects from './Projects.vue'
import VolunteerAvailability from './VolunteerAvailability.vue';
import { useMemberStore } from '@/stores/member'

export default {
  name: 'Member',
  components: {
    MemberContactDetails,
    Projects,
    Investment,
    VolunteerAvailability
  },

  setup () {
    const memberStore = useMemberStore()

    return {
      memberStore
    }
  }
}
</script>

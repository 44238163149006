<template>
  <div>
    <h2>My projects</h2>
    <p class="errorMessage">{{ volunteerProjectsStore.error }}</p>
    <div v-if="isLoading">Loading...</div>
    <div v-if="volunteerProjectsStore.projects.length > 0">
      <div v-for="project in volunteerProjectsStore.projects" :key="project.id">
        <h3>{{ project.name }}</h3>
        <p>Planned for {{ formatDate(project.plannedStartDate) }} and a duration of {{
          formatDuration(project.plannedDuration) }}.</p>
        <div v-if="project.matchingVolunteerReservations">
          <p>There are matching reservations from {{ project.volunteerReservations.length }} participants.</p>
        </div>
        <div v-else>
          <p><span style="color: red">There is no match of everybody's availability yet.</span> Maybe you can be available
            when the other volunteers are? If yes, please add a new matching date range. Unconfirmed reservations will be
            removed after {{ formatDate(project.removalDateForPendingReservations) }} in order to allow others to
            participate.</p>
          <ul v-for="(reservation, index) in project.volunteerReservations" :key="index">
            <li v-if="reservation.confirmedParticipant">
              <span v-if="reservation.volunteer.id === volunteerStore.volunteer.id">You are a confirmed
                participant</span>
              <div v-else>Volunteer {{ index }} is a confirmed participant with the following availability:
                <DateRanges :dateRanges="reservation.volunteer.availability.dateRanges" />
              </div>
            </li>
            <li v-else>
              <span v-if="reservation.volunteer.id === volunteerStore.volunteer.id">You have not entered your availability
                yet</span>
              <span v-else>Volunteer {{ index }} has not entered their availability yet.</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p v-else>You can sign up for projects in the Ecosystem Restoration Project. You can either
      participate as a volunteer or provide financial support. Please visit <router-link
        :to="{ name: 'communityOverview' }">the community project list</router-link>.
    </p>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import {
  formatMonthYear as helperFormatMonthYear,
  formatDuration as helperFormatDuration
} from '@/library'
import { useVolunteerProjectsStore } from '@/stores/volunteerProjects'
import { useVolunteerStore } from '@/stores/volunteer'
import DateRanges from './DateRanges.vue'


export default {
  name: 'MemberProjects',
  components: {
    DateRanges
  },

  setup () {
    const isLoading = ref(true)
    const volunteerProjectsStore = useVolunteerProjectsStore()
    const volunteerStore = useVolunteerStore()

    volunteerProjectsStore.getVolunteerProjects()
      .then(() => {
        isLoading.value = false
      })

    watch(() => volunteerStore.volunteer, () => {
      volunteerProjectsStore.getVolunteerProjects()
    })

    return {
      isLoading,
      volunteerStore,
      volunteerProjectsStore
    }
  },

  methods: {
    formatMonthYear (value) {
      return helperFormatMonthYear(value)
    },

    formatDate (value) {
      if (value) {
        return Intl.DateTimeFormat(navigator.language, {
          year: 'numeric', month: 'numeric', day: 'numeric'
        }).format(new Date(value))
      } else {
        return ''
      }
    },

    formatDuration (value) {
      return helperFormatDuration(value)
    },
  }
}
</script>

import { defineStore } from 'pinia'
import { ref, getCurrentInstance } from 'vue'
import { axiosInstance } from '@/library'

export const useVolunteerProjectsStore = defineStore('volunteerProjects', () => {
  const projects = ref([])
  const error = ref(null)
  const appInstance = getCurrentInstance()

  const getVolunteerProjects = () => {
    const memberPublicId = appInstance.appContext.config.globalProperties.$cookies.get('memberPublicId')

    return axiosInstance.get(`${window.VUE_APP_GRANJA}/api/v1/community/projects/scheduling/${memberPublicId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (response.status === 200) {
        projects.value = response.data
      }
    }
    )
    .catch((error) => {
      error.value = error.message
    })
  }

  return {
    projects,
    error,
    getVolunteerProjects
  }
})

<template>
  <div>
    <form v-if="requestOtp" @submit.prevent="requestOtpSubmit">
      <p>{{ $t('members.emailForm.introduction') }}</p>
      <legend>{{ $t('members.emailForm.formLegend') }}</legend>
      <div class="input-row">
        <label for="email">{{ $t('members.emailForm.emailLabel') }}</label>
        <input data-cy="email" type="email" name="email" v-model="email" :placeholder="$t('members.emailForm.emailLabel')"
          required />
        <span v-if="emailError" data-cy="errorMessage" class="error">{{ emailError }}</span>
      </div>
      <button data-cy="submit" :disabled="!isValidEmail">{{ $t('members.emailForm.submitButton') }}</button>
    </form>
    <form v-if="enterOtp" @submit.prevent="enterOtpSubmit">
      <p data-cy="successMessage" v-html="successMessage"></p>
      <legend>{{ $t('members.otpForm.formLegend') }}</legend>
      <div class="input-row">
        <label for="otp">{{ $t('members.otpForm.otpLabel') }}</label>
        <input data-cy="otp" type="text" name="otp" v-model="otp" :placeholder="$t('members.otpForm.otpLabel')"
          required />
        <span v-if="otpError" data-cy="errorMessage" class="error">{{ otpError }}</span>
      </div>
      <button data-cy="submit" :disabled="!isValidOtp">{{ $t('members.otpForm.submitButton') }}</button>
    </form>
  </div>
</template>

<script>
import { axiosInstance } from '@/library'

export default {
  name: 'LoginForm',
  data () {
    return {
      requestOtp: true,
      enterOtp: false,
      email: '',
      emailError: '',
      successMessage: '',
      otp: '',
      otpError: '',
      member: {}
    }
  },
  computed: {
    isValidEmail () {
      return this.emailError === '' && this.email !== '';
    },
    isValidOtp () {
      return this.otp !== '' && this.otp.length === 6;
    }
  },
  watch: {
    email (value) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,2,3,4,5,6,7,8,9}\.])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.emailError = re.test(String(value).toLowerCase()) ? '' : 'Invalid email';
    }
  },
  methods: {
    requestOtpSubmit () {
      if (this.isValidEmail) {
        axiosInstance.post(`${window.VUE_APP_GRANJA}/api/v1/members/request-otp`, {
          email: this.email,
          language: navigator.language
        })
          .then(response => {
            if (response.status === 200) {
              this.member = response.data;

              this.emailError = '';
              this.requestOtp = false;
              this.successMessage = this.$i18n.t('members.emailForm.successMessage', { email: this.email })
              this.enterOtp = true;
            }
          })
          .catch(() => { this.emailError = this.$i18n.t('members.emailForm.registrationError') })
      }
    },

    enterOtpSubmit () {
      if (this.isValidOtp) {
        axiosInstance.post(`${window.VUE_APP_GRANJA}/api/v1/members/login-otp/${this.member.memberPublicId}`, {
          oneTimePassword: this.otp
        })
          .then(response => {
            if (response.status === 200) {
              this.$cookies.set('memberPublicId', this.member.memberPublicId, { expires: '1D' })
              this.$emit('success')
            }
          })
          .catch(() => { this.otpError = this.$i18n.t('members.otpForm.otpError') })
      }
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
}
</style>

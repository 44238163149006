<template>
  <div>
    <h2>Availability</h2>
    <p v-if="volunteerStore.error" class="errorMessage">{{ volunteerStore.error }}</p>
    <p>I plan to be at Granja Caimito between these dates:</p>
    <table>
      <thead>
        <tr>
          <th>From</th>
          <th>To</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(dateRange, index) in volunteerStore.volunteer.availability.dateRanges" :key="index">
          <td>{{ dateRange.fromDate }}</td>
          <td>{{ dateRange.toDate }}</td>
          <td>
            <icon @click="volunteerStore.removeAvailability(index)" :path="mdiDelete" size="16" />
          </td>
        </tr>
        <tr>
          <td><input type="date" v-model="dateRange.fromDate" /></td>
          <td><input type="date" v-model="dateRange.toDate" /></td>
        </tr>
      </tbody>
    </table>
    <button class="action" @click="addDateRange(dateRange)">Add date range</button>
  </div>
</template>

<script>
import { useVolunteerStore } from '@/stores/volunteer'
import { mdiDelete } from '@mdi/js'
import Icon from 'vue3-icon'

export default {
  name: 'VolunteerAvailability',
  components: {
    Icon
  },

  data: () => ({
    dateRange: {},
  }),

  setup () {
    const volunteerStore = useVolunteerStore()

    return {
      mdiDelete,
      volunteerStore
    }
  },

  methods: {
    addDateRange (dateRange) {
      this.volunteerStore.addDateRange(dateRange)
    }
  }
}
</script>

<template>
  <div class="container">
    <h1>{{ $t("mainMenu.members") }}</h1>
    <Member v-if="showMembersArea" />
    <LoginForm v-else @success="onSuccessfulLogin" />
  </div>
</template>

<script>
import LoginForm from './LoginForm.vue';
import Member from './Member.vue';
import member from '@/mixins/member';

export default {
  name: 'Members',
  components: {
    LoginForm,
    Member
  },
  mixins: [member],

  data: () => ({
    membersAreaEnabled: false
  }),

  computed: {
    showMembersArea () {
      return this.membersAreaEnabled || this.isAuthenticatedMember();
    },
  },

  methods: {
    onSuccessfulLogin () {
      this.membersAreaEnabled = true;
    }
  }
}
</script>

<template>
  <div>
    <h2>My Investment</h2>
    <div v-if="memberStore.member.investor">
      Your investment details
    </div>
    <p v-else>You can invest into the Ecosystem Restoration Project and earn money long term and also get some perks along
      the way.</p>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'
import { useMemberStore } from '@/stores/member'

export default {
  name: 'MemberInvestment',

  setup () {
    const appInstance = getCurrentInstance()
    const memberPublicId = appInstance.appContext.config.globalProperties.$cookies.get('memberPublicId')
    const memberStore = useMemberStore()
    memberStore.loadMemberDetails(memberPublicId)

    return {
      memberStore
    }
  }
}
</script>

import { defineStore } from 'pinia'
import { ref, getCurrentInstance } from 'vue'
import { axiosInstance } from '@/library'

export const useVolunteerStore = defineStore('volunteer', () => {
  const volunteer = ref({
    member: {},
    availability: {
      dateRanges: []
    }
  })
  const error = ref(null)

  const getVolunteer = async (memberPublicId) => {
    try {
      const response = await axiosInstance.get(`${window.VUE_APP_GRANJA}/api/v1/community/volunteers/${memberPublicId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (response.status === 200) {
        volunteer.value = response.data
      }
    } catch (error) {
      error.value = error.message
    }
  }

  const removeAvailability = async (index) => {
    try {
      const memberPublicId = volunteer.value.member.memberPublicId
      const response = await axiosInstance.delete(`${window.VUE_APP_GRANJA}/api/v1/community/volunteers/${memberPublicId}/availability/${index}`)
      if (response.status === 204) {
        const availability = volunteer.value.availability
        availability.dateRanges.splice(index, 1)
        volunteer.value = { ...volunteer.value, availability: availability }
      }
    } catch (exception) {
      error.value = exception.message
    }
  }

  const addDateRange = async (dateRange) => {
    console.log('addDateRange', volunteer, dateRange)
    try {
      const memberPublicId = volunteer.value.member.memberPublicId
      const response = await axiosInstance.post(`${window.VUE_APP_GRANJA}/api/v1/community/volunteers/${memberPublicId}/availability`, dateRange)
      if (response.status === 204) {
        const availability = volunteer.value.availability
        availability.dateRanges.push(dateRange)
        volunteer.value = { ...volunteer.value, availability: availability }
      }
    } catch (exception) {
      console.log('addDateRange', exception)
      error.value = exception.message
    }
  }

  const appInstance = getCurrentInstance()
  const memberPublicId = appInstance.appContext.config.globalProperties.$cookies.get('memberPublicId')
  if (memberPublicId)
    getVolunteer(memberPublicId)

  return {
    volunteer,
    error,
    getVolunteer,
    removeAvailability,
    addDateRange
  }
})
